<!-- public -->
<div *ngIf="mode === 'public'" class="single-review-box">
    <web-star-rating [rating]="review?.surveyMeta.compositeRating" [max]="5" size="sm" [readonly]="true"
        [showLabel]="true"></web-star-rating>
    <div *ngIf="isProviderReview" class="col-md-12 col-12 col-sm-12 p-0">
        <h4>{{review.title}}</h4>
    </div>
    <web-slate-editor [content]="review.content" [isReadOnly]="true"></web-slate-editor>
    <h6>
        <figure class="image-box"><img appLazyLoad src="{{ review?.createdBy?.avatar_url ?? review?.createdBy?.avatar_default_url}}"
                alt=""></figure>{{review?.createdBy?.username}} <span>- {{review.createdAt | date: 'longDate'}}</span>
    </h6>

    <ngx-gallery *ngIf="galleryImages?.length" [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>

    <a style="position: absolute; right: 0; bottom: 0;" href="javascript:void(0)" (click)="viewJournal()" *ngIf="review?.journalId && isJournalFeatureEnabled">Journal View</a>
</div>

<!-- owner -->
<div *ngIf="mode === 'owner'" class="single-review-box owner">
    <h6 class="mb-2"><span>{{review.createdAt | date: 'medium'}}</span></h6>
    <div *ngIf="isProviderReview" class="col-md-12 col-12 col-sm-12 p-0 mb-3">
        <h5 class="provider-title"><a [routerLink]="['/provider-profile', review.replyPost.value.id]">{{review.replyPost.value.title}} <i class='bx bx-link'></i></a><br /><span class="provider-label">Provider</span>
        </h5>
    </div>

    <div class="row">
        <div class="col-md-6">
            <h6 class="review-title">{{review.title}}</h6>

            <table class="rating">
                <tr>
                    <td>Overall:</td>
                    <td rowspan="3">&nbsp;</td>
                    <td><web-star-rating [rating]="review?.surveyMeta.compositeRating" [max]="5" size="sm"
                            [readonly]="true" [showLabel]="true"></web-star-rating></td>
                </tr>
                <tr>
                    <td>Provider Rating:</td>
                    <td><web-star-rating [rating]="review?.surveyMeta.providerCompositeRating" [max]="5" size="sm"
                            [readonly]="true" [showLabel]="true"></web-star-rating></td>
                </tr>
                <tr>
                    <td>Staff Rating:</td>
                    <td><web-star-rating [rating]="review?.surveyMeta.staffCompositeRating" [max]="5" size="sm"
                            [readonly]="true" [showLabel]="true"></web-star-rating></td>
                </tr>
            </table>

            <web-slate-editor [content]="review.content" [isReadOnly]="true"></web-slate-editor>
            <ngx-gallery *ngIf="galleryImages?.length" [options]="galleryOptions"
                [images]="galleryImages"></ngx-gallery>
        </div>
        <div class="col-md-6">
            <!--<h6 *ngIf="optBinaryFeedback.length > 0">Additional Feedback</h6>-->
            <div *ngFor="let feedback of optBinaryFeedback">
                <i class='bx' [ngClass]="{ 'bxs-check-square': feedback.value === 'Y', 'bxs-x-square':  feedback.value === 'N' }"></i> {{feedback.label}}
            </div>
        </div>
    </div>

</div>

<!-- Timeline: public-journal-->
<ng-container *ngIf="mode === 'public-journal'">
    <div class="timeline-wrapper timeline-wrapper-primary" [ngClass]="{ 'timeline-inverted': journalIndex % 2 !== 0}" [attr.data-journalIndex]="'journalIndex-' + journalIndex" [attr.data-reviewid]="'review-' + review.id" >
        <div class="timeline-badge"> <i class='bx bx-calendar'></i></div>
        <div class="timeline-panel">
            <div class="timeline-heading">
                <web-star-rating [rating]="review?.surveyMeta.compositeRating" [max]="5" size="sm" [readonly]="true" [showLabel]="true"></web-star-rating>
                <h6 class="timeline-title">{{review.title}}</h6>
            </div>
            <div class="timeline-body">
                <p><web-slate-editor [content]="review.content" [isReadOnly]="true"></web-slate-editor></p>
                <ngx-gallery *ngIf="galleryImages?.length" [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
            </div>
            <div class="timeline-footer d-flex align-items-center flex-wrap">
                <span class="ms-auto"><i class="fe fe-calendar text-muted me-1 d-inline-flex"></i>{{review.createdAt |
                    date: 'longDate'}}</span>
            </div>
        </div>
    </div>
</ng-container>
