import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PayloadApiEndpointsService } from '../../services/payload-api-endpoints.service';

@Component({
  selector: 'web-prelaunch-signup',
  templateUrl: './prelaunch-signup.component.html',
  styleUrls: ['./prelaunch-signup.component.scss'],
})
export class PrelaunchSignupComponent {

  formSubmitted = false;
  states: string[] = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
    'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
    'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
    'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
    'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
    'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  signupForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  })

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private payloadApiEndpoints: PayloadApiEndpointsService) { }

  onSubmit() {
    const endpoint = this.payloadApiEndpoints.routes.webhook.sendgrid.post;

    this.http.post(endpoint, { email: this.signupForm.value.email })
      .subscribe(response => {
        console.log('Form submitted successfully', response);
        this.formSubmitted = true;
      }, error => {
        console.error('Form submission error', error);
        this.formSubmitted = false;
      });
  }
}
