import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FEATURE_FLAGS, IUser } from '@sw-cms/shared-types';
import { FeatureFlagService } from '../../services';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'web-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() user: IUser | null | undefined = null;
  @Input() isLoggedIn: boolean = false;

  company: any = environment.company;
  currentYear: number = (new Date()).getFullYear();
  social: any = environment.social;
  providerSignUpEnabled = environment.toggle.providerSignUpEnabled;
  providerSignUpLaunchDesc = environment.toggle.providerSignUpLaunchDesc;
  isProviderRoute = false;

  private routerSubscription: Subscription;

  constructor(
    private featureFlagService: FeatureFlagService,
    private router: Router) { }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {
      // @ts-ignore
      const currentUrl = event?.routerEvent?.url.toLowerCase();
      this.isProviderRoute = currentUrl?.includes('/provider/');
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

}
