
<!-- main header -->
<header class="main-header style-two" [class.fixed-header]="isSticky">
    <!--<web-maintenance-banner></web-maintenance-banner>-->
    <!-- header-lower -->
    <div class="header-lower">
        <div class="auto-container">
            <div class="outer-box">
                <div class="logo-box">
                    <figure class="logo"><a href="index.html"><img src="assets/images/logo-3.png" alt=""></a></figure>
                </div>
                <div class="menu-area">
                    <ng-template [ngTemplateOutlet]="carecomm"></ng-template>
                    <!--<a [href]="forum.link" class="theme-btn-one x-small-scale">Care Community</a>-->
                    <!--Mobile Navigation Toggler-->
                    <div class="mobile-nav-toggler" (click)="toggleMobile()">
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                    </div>
                    <nav class="main-menu navbar-expand-md navbar-light">
                        <web-header-links [user]="user" [isLoggedIn]="isLoggedIn"></web-header-links>
                    </nav>
                </div>
                <web-header-avatar style="display: contents;" [user]="user" [isLoggedIn]="isLoggedIn"></web-header-avatar>
            </div>
        </div>
    </div>

    <!--sticky Header-->
    <div class="sticky-header">
        <!--<web-maintenance-banner></web-maintenance-banner>-->
        <div class="auto-container">
            <div class="outer-box">
                <div class="logo-box">
                    <figure class="logo"><a href="index.html"><img src="assets/images/small-logo.png" alt=""></a>
                    </figure>
                </div>
                <div class="menu-area">
                    <ng-template [ngTemplateOutlet]="carecomm"></ng-template>
                    <!--Mobile Navigation Toggler-->
                    <div class="mobile-nav-toggler" (click)="toggleMobile()">
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                    </div>
                    <nav class="main-menu clearfix">
                        <web-header-links [user]="user" [isLoggedIn]="isLoggedIn"></web-header-links>
                    </nav>
                </div>
                <web-header-avatar  style="display: contents;"  [user]="user" [isLoggedIn]="isLoggedIn"></web-header-avatar>
            </div>
        </div>
    </div>
</header>
<!-- main-header end -->

<!-- Mobile Menu  -->
<div class="mobile-menu">
    <div class="menu-backdrop" (click)="toggleMobile()"></div>
    <div class="close-btn" (click)="toggleMobile()"><i class="fas fa-times"></i></div>
    
    <nav class="menu-box">
        <div class="nav-logo"><a href="index.html"><img src="assets/images/logo-2.png" alt="" title=""></a></div>
        <div *ngIf="!isLoggedIn" class="mb-5 text-center">
            <a [routerLink]="['/auth/login']" class="theme-btn-one small-scale">Sign up / Login<i class="forum.icon"></i></a>
        </div>
        <div *ngIf="isLoggedIn" class="mb-5 text-center">
            {{user?.username}}<br/>
            {{user?.email | emailMask}}<br/>
            <sup>Role: {{user?.roles?.join()}}</sup><br/>
        </div>
        <div class="menu-outer">
            <web-header-links [isLoggedIn]="isLoggedIn"></web-header-links>
        </div>

        <div class="contact-info">
            <h4>Contact Info</h4>
            
            <ul>
                <!--<li>ADDRESS</li>
                <li><a href="tel:TBD">TBD</a></li>-->
                <li><a [href]="'mailto:' + company.email.info">{{company.email.info}}</a></li>
            </ul>
            
        </div>
        <div class="social-links">
            <ul class="clearfix">
                <li><a [href]="social?.facebook" target="_blank"><span class="fab fa-facebook-square"></span></a></li>
                <li><a [href]="social?.insta" target="_blank"><span class="fab fa-instagram"></span></a></li>
                <li><a [href]="social?.linkedIn" target="_blank"><span class="fab fa-linkedin"></span></a></li>
            </ul>
        </div>
    </nav>
</div><!-- End Mobile Menu -->

<button class="scroll-top scroll-to-target open" (click)="onScrollTop()" [class.open]="isSticky" data-target="html">
    <span class="fa fa-arrow-up"></span>
</button>

<ng-template #carecomm>
    <a [href]="forum.link" type="button" class="theme-btn-one x-small-scale position-relative me-2">
        Care Community
        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
        <i class='bx bx-chat ms-0'></i>
          <span class="visually-hidden">active users</span>
        </span>
    </a>
</ng-template>

