import { Component, OnDestroy, OnInit } from '@angular/core';
import { FeatureFlagService, NavigationService, ScriptInjectorService } from './shared/services';
import { environment } from '../environments/environment';
import { FEATURE_FLAGS } from '@sw-cms/shared-types';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'angular-directory';
  loading = false;
  private subscription!: Subscription;

  constructor(
    private featureFlagService: FeatureFlagService,
    private navigationService: NavigationService,
    private router: Router,
    private scriptInjectorService: ScriptInjectorService) {

  }

  ngOnInit() {
    this.navigationService.loading$.subscribe((loading) => {
      this.loading = loading;
    });

    this.loadUmamiScript();

    /*
    this.featureFlagService
      .pollFeatureFlag(FEATURE_FLAGS.PUB_SITE_ACCESS, 10) // Check every 10 seconds
      .subscribe((result) => {
        if(!result.isEnabled) {
          this.router.navigate(['/error-403']);
        }
      });
    */
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadUmamiScript(): void {
    const scriptSrc = environment.umami.url;
    const websiteId = environment.umami.websiteId;

    this.scriptInjectorService.injectScript(scriptSrc, {
      'data-website-id': websiteId
    });
  }
  
}
