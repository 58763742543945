<div>
    <div class="auto-container">
        <div *ngIf="!formSubmitted"  class="inner-box">
            <div class="content-box">

                <div class="row align-items-center clearfix text-center mb-3">
                    <div class="col-lg-12">
                        <div class="sec-title mb-2">
                            <h2 style="font-size: 42px;">Be the first to know when we launch!</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 mb-4">
                        Sign up with your email to receive exclusive updates about our official launch. Plus, get a sneak peek at the state we’ll launch in next!
                    </div>
                    <div class="col-md-7">
                        <div class="content_block_4">
                            <div class="content-box">
                                <form [formGroup]="signupForm" class="subscribe-form">
                                    <div class="form-group">
                                        <input type="email" name="email" placeholder="Your Email" required="" formControlName="email">
                                        <button (click)="onSubmit()" [disabled]="signupForm.invalid" class="theme-btn-one">Submit now<i class="icon-Arrow-Right"></i></button>

                                        <div *ngIf="signupForm.get('email')?.errors && signupForm.get('email')?.touched">
                                            <i class='bx bxs-shield-minus negative'></i> Email Required.
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="formSubmitted" class="inner-box">
            <div class="content-box">
                <div class="row align-items-center clearfix text-center mb-3">
                    <div class="col-lg-12">
                        <div class="sec-title mb-2">
                            <h2 style="font-size: 42px;">Thank you for signing up!</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>