<!-- Start Footer Area -->
<footer class="main-footer">
    <div class="footer-top">
        <div class="pattern-layer">
            <!---->
                <div class="pattern-1" style="background-image: url(assets/images/shape/shape-30.png);"></div>
                <div class="pattern-2" style="background-image: url(assets/images/shape/shape-31.png);"></div>
            
        </div>
        <div class="auto-container">
            <div class="widget-section">
                <div class="row clearfix">
                    <div *ngIf="!isProviderRoute" class="col-lg-3 col-sm-6 col-md-6 footer-column">
                        <div class="footer-widget links-widget">
                            <div class="widget-title">
                                <h3>Community Users</h3>
                            </div>
                            <div class="widget-content">
                                <ul class="links clearfix">
                                    <li *ngIf="!user"><a routerLink="/"><i class="flaticon-right-chevron"></i> Create an account</a></li>
                                    <li *ngIf="!user"><a routerLink="/"><i class="flaticon-right-chevron"></i> Sign Up Process</a></li>
                                    <li><a routerLink="/"><i class="flaticon-right-chevron"></i> Find a Doctor</a></li>
                                    <li><a routerLink="/" fragment="about-us"><i class="flaticon-right-chevron"></i> Social Healthcare Platform</a></li>
                                    <li><a [routerLink]="isLoggedIn ? ['/care-community'] : ['.']" [attr.fragment]="isLoggedIn ? 'care-community' : '#care-community'" ><i class="flaticon-right-chevron"></i> Care Community</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-md-6 footer-column">
                        <div class="footer-widget links-widget">
                            <div class="widget-title">
                                <h3>Providers</h3>
                            </div>
                            <div class="widget-content">
                                <ul class="links clearfix">
                                    <li><a routerLink="/provider-service"><i class="flaticon-right-chevron"></i> Provider Information</a></li>
                                    <li>
                                        <a *ngIf="providerSignUpEnabled" routerLink="/provider-service" fragment="signup"><i class="flaticon-right-chevron"></i> Provider Sign up</a>
                                        <a *ngIf="!providerSignUpEnabled" [class.disabled]="!providerSignUpEnabled" [title]="'Coming Soon: ' + providerSignUpLaunchDesc"><i class="flaticon-right-chevron"></i> Provider Sign up - {{providerSignUpLaunchDesc}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-md-6 footer-column">
                        <div class="footer-widget links-widget">
                            <div class="widget-title">
                                <h3>Help</h3>
                            </div>
                            <div class="widget-content">
                                <ul class="links clearfix">
                                    <li><a routerLink="/faq"><i class="flaticon-right-chevron"></i> Frequently Asked Questions</a></li>
                                    <li><a routerLink="/" fragment="care-community"><i class="flaticon-right-chevron"></i> How to Join</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-md-6 footer-column">
                        <div class="footer-widget links-widget">
                            <div class="widget-title">
                                <h3>Our Company</h3>
                            </div>
                            <div class="widget-content">
                                <ul class="links clearfix">
                                    <li><a routerLink="/about-us"><i class="flaticon-right-chevron"></i> Company Overview</a></li>
                                    <li><a href="https://socialwellbeing.zohorecruit.com/jobs/Careers" target="_blank"><i class="flaticon-right-chevron"></i> Careers</a></li>
                                    <li><a routerLink="/contact-us"><i class="flaticon-right-chevron"></i> Contact Us</a></li>
                                    <li><a routerLink="/legal/privacy"><i class="flaticon-right-chevron"></i> Privacy Policy</a></li>
                                    <li><a routerLink="/legal/cookies"><i class="flaticon-right-chevron"></i> Cookie Policy</a></li>
                                    <li><a routerLink="/legal/disclaimers"><i class="flaticon-right-chevron"></i> Disclaimers</a></li>
                                    <li><a routerLink="/legal/terms"><i class="flaticon-right-chevron"></i> Terms of Use</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-lg-12 footer-column mt-5">
                        <div class="footer-widget links-widget">
                            <div class="widget-title">
                                <h3>Follow Us</h3>
                            </div>
                            <div class="widget-content">
                                <ul class="social-links">
                                    <li><a [href]="social?.facebook" class="d-block" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                    <li><a [href]="social?.insta" class="d-block" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                                    <li><a [href]="social?.linkedIn" class="d-block" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="auto-container">
            <div class="inner-box clearfix">
                <div class="copyright pull-left"><p>© {{currentYear}} {{company?.name}} | All Rights Reserved</p></div>
                <ul class="footer-nav pull-right clearfix">
                    <li><a routerLink="/legal/terms">Terms of Service</a></li>
                    <li><a routerLink="/legal/privacy">Privacy Policy</a></li>
                    <li><a routerLink="/legal/cookies">Cookie Policy</a></li>
                    <li><a routerLink="/legal/disclaimers">Disclaimers</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->