import { Injectable } from '@angular/core';
import { join } from 'lodash';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IFaq, IFaqSearchCriteria, IFaqSearchResult } from '../models';
import { Observable, catchError, throwError, map } from 'rxjs';
import * as qs from 'qs'
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  private collection: string = 'faqs';
  private baseUrl: string = join([environment.api.url, 'api', this.collection], '/');

  constructor(private http: HttpClient) { }

  getFaqs = (criteria: IFaqSearchCriteria): Observable<IFaqSearchResult> => {
    let url = `${this.baseUrl}?where[isActive][equals]=true&`;

    //TODO: Centralize logic
    if (criteria.page) {
      url += `page=${criteria.page}&`;
    }

    if (criteria.limit) {
      url += `limit=${criteria.limit}&`;
    }

    if (criteria.depth) {
      url += `depth=${criteria.depth}&`;
    }

    if (criteria.query) {
      const stringifiedQuery = qs.stringify(
        {
          where: criteria.query, // ensure that `qs` adds the `where` property, too!
        },
        { addQueryPrefix: true },
      )

      url += `${stringifiedQuery}&`;
    }

    if (criteria.sort) {
      url += `sort=${criteria.sort}&`;
    }
    //End of TODO

    return this.http
      .get<any>(url)
      .pipe(
          map(res => res.payload),
          catchError((err: HttpErrorResponse) => throwError(err)));
  }

  getFaq = (criteria: IFaqSearchCriteria): Observable<IFaq> => {
    let url = `${this.baseUrl}${criteria.id}?`;

    if (criteria.depth) {
      url += `depth=${criteria.depth}&`;
    }

    return this.http
      .get<any>(url)
      .pipe(
        map(res => res.payload),
        catchError((err: HttpErrorResponse) => throwError(err)));
  }
}
