// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentType } from './EnvironmentType';

export const environment: EnvironmentType = {
  production: false,
  app: { name: 'sw-cms', stage: 'dev', auth: 'discourse' },
  company: {
    name: "Social Wellbeing, LLC",
    email: {
      info: "info@socialwellbeing.com"
    }
  },
  api: { 
    url: 'https://dev-cms-api.socialwellbeing.io',
    endpoints: {
      cms: 'admin',
      sso: {
        login: 'auth/sso/init',
        logout:'auth/sso/logout'
      }
    } 
  },
  payload: { users_slug: 'users' },
  web: {
    url: 'https://dev.socialwellbeing.io/',
    endpoints: {
      sso: {
        login: 'auth/sso',
        logout: 'auth/sso/logout'
      }
    }
  },
  google: {
    maps: {
      token: 'AIzaSyAlsLRLlQaJUb1hTaayo4zThNVqykOhXZA'
    }
  },
  discourse: {
    url: 'https://discourse-dev.socialwellbeing.io',
    label: 'Sign-In / Join',
    authLabel: 'Chime In',
    icon: 'icon-Arrow-Right'
  },
  umami: {
    url: 'https://umami.socialwellbeing.io/script.js',
    websiteId: '05804854-3d56-48eb-93c0-a45d86bc8615'
  },
  unleash: {
    url: 'https://unleash.socialwellbeing.io/api/frontend',
    clientKey: '*:development.22bc0951343d29d1a76adabca8706d85e4f527f6a4a5aa7a22828b14'
  },
  stripe: {
    pricingTables: [
      {
        id: 'prctbl_1PPyzp2MLsSi7goNK9vNBQTx',
        key: 'pk_test_51PPql02MLsSi7goNqzS9OdvSIbvk1Vm1bYb3ojSn4kvurbWwZX617wAUilElRSpeIhH13mPzcyqDGH3fJQQ708L900Wdco7PlM',
        group: 'test'
      }
    ],
    identity: {
      flow: {
        url: 'https://dev-cms-api.socialwellbeing.io/api/webhook/stripe/identity/verification/session/create'
      }
    },
    checkout: {
      customerPortal: {
        url: 'https://billing.stripe.com/p/login/test_3csbKx1RR1ADcOA9AA'
      }
    }
  },
  social: {
    insta: 'https://www.instagram.com/yoursocialwellbeing?igsh=ZXNjNGtuaG1xNGxi',
    facebook: 'https://www.facebook.com/profile.php?id=61568873872972&mibextid=ZbWKwL',
    linkedIn: 'https://www.linkedin.com/company/social-wellbeing/'
  },
  toggle: {
    providerSignUpEnabled: false,
    providerSignUpLaunchDesc: "Q2 2025"
  },
  termly: {
    tos: {
      policyUUID: '81db41e6-2a8f-4074-9597-1f4031721fa5'
    },
    disclaimer: {
      policyUUID: '51b75af8-e640-4a25-b9f5-09948f15dc2a'
    },
    privacy: {
      policyUUID: 'd74eb702-b14c-4f66-844d-f7f2b4a6608e'
    },
    cookie: {
      policyUUID: '2eef5006-3629-4b9f-91ba-a4a99b9ff9cd'
    },
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
